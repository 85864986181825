import {
  Button,
  colors,
  fontCalcMixin,
  fontSizes,
  Grid,
  Notification,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TaskNotification = styled(Notification)`
  width: max-content !important;
  height: 53px;
  border-radius: 8px;
  position: relative;

  svg[data-testid='task-icon'] {
    width: 23px;
    height: 23px;
  }
`;

export const InlineWrapper = styled(Grid)`
  gap: ${spacing.s4};
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  ${fontCalcMixin(fontSizes.base)};
`;

export const TaskButton = styled(Button)<{ isOverdue?: boolean }>`
  min-height: 37px;
  padding: 0 ${spacing.s4};
  background-color: ${({ isOverdue }) =>
    isOverdue
      ? colors.notifications.light.infoDark
      : colors.secondaryBlue.blue06};
  border-color: ${({ isOverdue }) =>
    isOverdue
      ? colors.notifications.light.infoDark
      : colors.secondaryBlue.blue06};
  font-weight: 400;
  ${fontCalcMixin(fontSizes.sm)};

  &:hover {
    background-color: ${({ isOverdue }) =>
      isOverdue
        ? colors.notifications.light.errorDark
        : colors.secondaryBlue.blue08};
    border-color: ${({ isOverdue }) =>
      isOverdue
        ? colors.notifications.light.errorDark
        : colors.secondaryBlue.blue08};
  }
`;

export const CloseButton = styled.button<{ isDisabled?: boolean }>`
  box-shadow: 0px 3.33px 8.33px 0.83px #7090b033;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
`;
