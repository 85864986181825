import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { ListView, type ListViewRow } from '@bt-healthcare/ui-toolkit';

import {
  PatientListColumn,
  type PatientListRendererProps,
} from './PatientList.types';
import { ROUTE } from 'config/routes';
import { ListViewSkeleton } from 'components/Skeletons/ListViewSkeleton';
import * as config from 'pages/PatientList/PatientListConfig';
import { NoResultsFound } from 'components/NoResultsFound';
import type { WardPatientSearchRecord } from 'services/graphql';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const PatientListRenderer = ({
  patientListCount,
  loadNext,
  hasMore,
  setSortingQuery,
  sortParams,
  data,
  loading,
}: PatientListRendererProps) => {
  const navigate = useNavigate();
  const { isTaskListFeatureFlagEnabled } = useFeatureFlags();

  const handleRowClick = ({ id }: ListViewRow) => {
    navigate(ROUTE.PATIENT_DETAIL, {
      state: {
        wardPatientId: id,
      },
    });
  };

  return loading && patientListCount === 0 ? (
    <ListViewSkeleton count={10} />
  ) : (
    <InfiniteScroll
      dataLength={patientListCount}
      next={loadNext}
      hasMore={hasMore}
      loader={null}
    >
      <ListView
        id="patients"
        onRowClick={handleRowClick}
        columnWidths={config.columnWidths(isTaskListFeatureFlagEnabled)}
        headerData={config.headerData(
          setSortingQuery,
          sortParams,
          !isTaskListFeatureFlagEnabled ? [PatientListColumn.NextTaskDue] : []
        )}
        rowData={config.toRows(
          (data?.wardPatientsSearch.searchResults ||
            []) as WardPatientSearchRecord[],
          !isTaskListFeatureFlagEnabled ? [PatientListColumn.NextTaskDue] : []
        )}
      />
      {!loading && data?.wardPatientsSearch?.searchResults?.length === 0 && (
        <NoResultsFound />
      )}
    </InfiniteScroll>
  );
};
