import {
  Text,
  colors,
  Grid,
  Stack,
  Spacer,
  BTHealthIcon,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import { useEffect, useMemo, useState } from 'react';
import { TasksFilter } from '../TasksFilter';
import { TaskCard } from '../TaskCard';
import type { TaskAttributes } from '../types';
import { filterTasks } from './utils';
import { useTasks } from 'context/tasks/TasksContext';
import { TaskListStatus } from 'services/graphql';

export const TasksList = () => {
  const { tasks: allTasks } = useTasks();
  const tasks = useMemo(
    () => allTasks.filter((task) => task.status !== TaskListStatus.Draft),
    [allTasks]
  );
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any>([]);

  useEffect(() => {
    if (filterStatuses.length === 0) {
      setFilteredData(tasks);
      return;
    }

    setFilteredData(filterTasks(tasks, filterStatuses));
  }, [filterStatuses]);

  useEffect(() => {
    setFilteredData(tasks);
  }, [tasks]);

  if (tasks.length === 0) {
    return (
      <Stack id="no-tasks-list" alignItems="center">
        <Spacer size="s6" />
        <BTHealthIcon icon="TaskColored" color={colors.grey.grey08} size={60} />
        <Spacer size="s6" />
        <Text fontWeight={500} color={colors.grey.grey09}>
          No tasks have been submitted
        </Text>
        <Spacer size="s2" />
        <Text
          color={colors.grey.grey08}
          fontSize={fontSizes.sm}
          style={{ textAlign: 'center' }}
        >
          Your tasks will appear here as and when you submit them.
        </Text>
      </Stack>
    );
  }

  return (
    <Stack id="tasks-list">
      <Spacer size="s6" />
      <Grid
        style={{
          gridTemplateColumns: '1fr max-content',
          alignItems: 'center',
        }}
      >
        <Text color={colors.grey.grey10} fontWeight={500}>
          All tasks{' '}
          <Text as="span" color={colors.grey.grey08}>
            ({filteredData.length})
          </Text>
        </Text>
        <TasksFilter
          filterStatuses={filterStatuses}
          setFilterStatuses={setFilterStatuses}
        />
      </Grid>
      <Spacer size="s3" />
      <Stack id="tasks-stack" space="s2">
        {filteredData.map((task: TaskAttributes) => (
          <TaskCard key={task.id} task={task} />
        ))}
      </Stack>
    </Stack>
  );
};
