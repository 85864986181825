import {
  ErrorModal,
  ErrorNotification,
  SpinnerLoader,
  SubHeader,
  Text,
  Wrapper,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HypertensionAssessmentConfirmHeaderWrapper,
  BackButtonWrapper,
  HypertensionAssessmentConfirmContentLayout,
  CardWrapper,
} from './styles';
import {
  assessmentConfirmationMapping,
  carePlanConfirmationMapping,
} from './HypertensionAssessmentConfirm.mapping';
import {
  getConsultationTimeframeDate,
  getWardPatientConditionAssessmentInput,
} from './utils';
import { useTracking } from 'hooks/useTracking';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { FormFooter } from 'components/FormFooter';
import { BackButton } from 'components/BackButton';
import { ROUTE } from 'config/routes';
import { HypertensionAssessmentSummaryCard } from 'components/HypertensionAssessmentSummaryCard';
import { HypertensionAssessmentStatusCard } from 'components/HypertensionAssessmentStatusCard';
import { ConfirmAssessment } from 'components/Modal/ConfirmAssessment';
import { FETCH_POLICY } from 'App.constants';
import {
  AssessmentType,
  ConsultationTimeframe,
  TaskListStatus,
  TaskListType,
  useCreateWardPatientConditionAssessmentMutation,
  useGetWardPatientStatusesQuery,
  YesNoType,
} from 'services/graphql';
import { getAssessmentStatuses } from 'components/HypertensionAssessmentStatusCard/utils';
import { isEmptyOrNil } from 'utils/object.utils';
import { CancelAssessment } from 'components/Modal';
import { PageName } from 'config/pageNames';
import { useAssessmentTracking } from 'hooks/useAssessmentTracking';
import { usePatient } from 'context/patient/PatientContext';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';
import { TaskListTypeMap } from 'mappings/enums';

export const HypertensionAssessmentConfirm = () => {
  const wardPatientId = useRedirectAndReturnState('wardPatientId');
  const patient = useRedirectAndReturnState('patient');
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  const { trackTappedAssessment } = useAssessmentTracking();
  const { isTaskListFeatureFlagEnabled } = useFeatureFlags();

  const { hypertensionAssessmentFormData } = usePatient();
  useEffect(() => {
    trackPage(PageName.ASSESSMENT_HYPERTENSION_CONFIRM);
  }, []);
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useGetWardPatientStatusesQuery({
    variables: { wardPatientId },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  const [
    createWardPatientConditionAssessmentMutation,
    { loading: mutationSubmitting, error },
  ] = useCreateWardPatientConditionAssessmentMutation();

  const {
    onSubmit,
    loading: taskCreating,
    error: taskError,
  } = useAddOrUpdateTaskList();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleBackButtonClick = () => {
    navigate(ROUTE.ASSESSMENT_HYPERTENSION, {
      state: { wardPatientId, patient, isNavigateBack: true },
    });
  };

  const handleConfirm = () => {
    trackTappedAssessment('assessment/hypertension/confirm_assessment');
    setErrorModalOpen(false);
    const input = getWardPatientConditionAssessmentInput(
      hypertensionAssessmentFormData,
      wardPatientId
    );

    if (isTaskListFeatureFlagEnabled) {
      const { consultationRequiredYN, consultationTimeframe, testsRequiredYN } =
        hypertensionAssessmentFormData;

      if (consultationRequiredYN === YesNoType.Yes) {
        onSubmit({
          task: {
            label: TaskListTypeMap[TaskListType.SendConsultationInvite],
            value: TaskListType.SendConsultationInvite,
          },
          status: TaskListStatus.Draft,
          taskDueDate: getConsultationTimeframeDate(
            consultationTimeframe?.value!
          ),
          other: null,
        });
      }

      if (testsRequiredYN === YesNoType.Yes) {
        onSubmit({
          task: {
            label: TaskListTypeMap[TaskListType.SendTestInvite],
            value: TaskListType.SendTestInvite,
          },
          status: TaskListStatus.Draft,
          taskDueDate: getConsultationTimeframeDate(
            ConsultationTimeframe.WithinTwoDays
          ),
          other: null,
        });
      }
    }

    createWardPatientConditionAssessmentMutation({
      variables: { input },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        trackTappedAssessment(
          'assessment/hypertension/confirm_assessment_completed'
        );
        setConfirmModalOpen(true);
      },
    });
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const hypertensionAssessment = assessmentConfirmationMapping(
    hypertensionAssessmentFormData,
    patient
  );

  const hypertensionCarePlan = carePlanConfirmationMapping(
    hypertensionAssessmentFormData
  );

  const assessmentStatuses = getAssessmentStatuses(
    hypertensionAssessmentFormData,
    queryData
  );

  useEffect(() => {
    if (error || taskError) {
      trackTappedAssessment(
        'assessment/hypertension/confirm_assessment_errored'
      );
      setErrorModalOpen(true);
    }
  }, [error]);

  if (queryLoading) {
    return <SpinnerLoader id="statuses-loader" data-testid="statuses-loader" />;
  }

  return (
    <>
      <HypertensionAssessmentConfirmHeaderWrapper>
        {(mutationSubmitting || taskCreating) && (
          <SpinnerLoader
            id="assessment-create-loader"
            data-testid="assessment-create-loader"
            asModal
            text="Submitting assessment"
          />
        )}
        <Wrapper>
          <BackButtonWrapper>
            <BackButton handleBackButton={handleBackButtonClick} />
          </BackButtonWrapper>
          <SubHeader color="indigo08">
            Confirm assessment and status updates
          </SubHeader>
          <Text color={colors.grey.grey09}>
            Please review the information below, once you&apos;re happy with it,
            press confirm to complete the assessment and status updates.
          </Text>
        </Wrapper>
      </HypertensionAssessmentConfirmHeaderWrapper>

      <HypertensionAssessmentConfirmContentLayout>
        <CardWrapper>
          <HypertensionAssessmentSummaryCard
            title="Assessment summary"
            confirmationDetails={hypertensionAssessment}
          />
          {hypertensionAssessmentFormData.assessmentType.value ===
            AssessmentType.Consultation && (
            <HypertensionAssessmentSummaryCard
              title="Hypertension Care Plan"
              confirmationDetails={hypertensionCarePlan}
            />
          )}
        </CardWrapper>
        <Wrapper>
          {queryError ? (
            <ErrorNotification
              id="ward-patient-statuses"
              action="loading the current statuses and will not be able to proceed with the assessment"
              onTryAgainClick={() => refetch()}
              space="none"
            />
          ) : (
            <HypertensionAssessmentStatusCard
              assessmentStatuses={assessmentStatuses}
            />
          )}
        </Wrapper>
      </HypertensionAssessmentConfirmContentLayout>

      {!confirmModalOpen && (
        <FormFooter
          primaryButtonClick={handleConfirm}
          cancelButtonClick={handleCancel}
          primaryButtonText="Confirm"
          primaryButtonDisabled={!isEmptyOrNil(queryError)}
          isFormWidth={false}
        />
      )}

      <ErrorModal
        isModalOpen={errorModalOpen}
        secondaryButtonText="Cancel"
        onSecondaryAction={() => setErrorModalOpen(false)}
        onPrimaryAction={handleConfirm}
        primaryButtonText="Try again"
        title="We couldn't submit the assessment"
      >
        The assessment could not be submitted due to an error. Please review the
        information you entered and try again. If the problem persists, please
        contact us for assistance.
      </ErrorModal>

      <ConfirmAssessment
        modalOpen={confirmModalOpen}
        wardPatientId={wardPatientId}
      />

      <CancelAssessment
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        wardPatientId={wardPatientId}
      />
    </>
  );
};
