import { colors, Text, Tooltip } from '@bt-healthcare/ui-toolkit';
import { format } from 'date-fns';
import type { NextTaskDueProps } from './types';
import { getTaskColor, getTaskText } from './NextTaskDue.util';
import { NextTaskDueWrapper } from './styles';
import { TaskListStatus } from 'services/graphql';

export const NextTaskDue = ({
  nextTaskDueDateTime,
  nextTaskDueType,
  nextTaskDueTypeDetail,
  nextTaskDueStatus,
}: NextTaskDueProps) => {
  const hasTaskDue =
    nextTaskDueType !== null && nextTaskDueStatus === TaskListStatus.Pending;

  if (!hasTaskDue) {
    return <Text color={colors.grey.grey08}>--</Text>;
  }

  const formattedDate = format(new Date(nextTaskDueDateTime!), 'dd/MM/yy');

  return (
    <NextTaskDueWrapper>
      <Tooltip
        id="next-task-due-tooltip"
        content={getTaskText(nextTaskDueType, nextTaskDueTypeDetail!)}
        direction="top-end"
      >
        <Text color={getTaskColor(formattedDate)}>{formattedDate}</Text>
      </Tooltip>
    </NextTaskDueWrapper>
  );
};
