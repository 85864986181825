import type { TasksActions, TasksState } from './types';
import type {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
} from 'services/graphql';
import type { TaskAttributes } from 'components/TaskList/types';

export const initTasksState = {
  tasks: [] as TaskAttributes[],
  taskDetails: undefined as TaskAttributes | undefined,
  showDeleteTaskModal: false,
  personId: '',
  isEditTask: false,
  isCheckTask: false,
  showUncompleteTaskModal: false,
  currentConsultationStatus:
    null as MonitoredConditionConsultationStatusType | null,
  currentTestStatus: null as MonitoredConditionTestStatusType | null,
};

export const findTask = (state: TasksState, taskId: string) =>
  state.tasks.find((x) => x.id === taskId) ?? initTasksState.taskDetails;

export const tasksReducer = (
  state: TasksState,
  action: TasksActions
): TasksState => {
  switch (action.type) {
    case 'setTasks': {
      const { tasks } = action;
      return {
        ...state,
        tasks,
      };
    }
    case 'resetTasks': {
      return {
        ...state,
        tasks: initTasksState.tasks,
        currentConsultationStatus: initTasksState.currentConsultationStatus,
        currentTestStatus: initTasksState.currentTestStatus,
      };
    }
    case 'setTaskDetails': {
      return {
        ...state,
        taskDetails: findTask(state, action.taskId),
      };
    }
    case 'resetTaskDetails': {
      return {
        ...state,
        taskDetails: initTasksState.taskDetails,
      };
    }
    case 'setShowDeleteTaskModal': {
      return {
        ...state,
        showDeleteTaskModal: action.showDeleteTaskModal,
      };
    }
    case 'resetShowDeleteTaskModal': {
      return {
        ...state,
        showDeleteTaskModal: initTasksState.showDeleteTaskModal,
      };
    }
    case 'setPersonId': {
      return {
        ...state,
        personId: action.personId,
      };
    }
    case 'resetPersonId': {
      return {
        ...state,
        personId: initTasksState.personId,
      };
    }
    case 'setIsEditTask': {
      return {
        ...state,
        isEditTask: action.isEditTask,
      };
    }
    case 'resetIsEditTask': {
      return {
        ...state,
        isEditTask: initTasksState.isEditTask,
      };
    }
    case 'setCheckTask': {
      return {
        ...state,
        taskDetails: findTask(state, action.taskId),
        isCheckTask: action.isCheckTask,
      };
    }
    case 'resetCheckTask': {
      return {
        ...state,
        taskDetails: initTasksState.taskDetails,
        isCheckTask: initTasksState.isCheckTask,
      };
    }
    case 'setShowUncompleteTaskModal': {
      return {
        ...state,
        taskDetails: findTask(state, action.taskId),
        showUncompleteTaskModal: action.showUncompleteTaskModal,
      };
    }
    case 'resetShowUncompleteTaskModal': {
      return {
        ...state,
        taskDetails: initTasksState.taskDetails,
        showUncompleteTaskModal: initTasksState.showUncompleteTaskModal,
      };
    }
    case 'setCurrentConsultationStatus': {
      return {
        ...state,
        currentConsultationStatus: action.currentConsultationStatus,
      };
    }
    case 'setCurrentTestStatus': {
      return {
        ...state,
        currentTestStatus: action.currentTestStatus,
      };
    }
    default: {
      return state;
    }
  }
};
