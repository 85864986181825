import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isToday from 'date-fns/isToday';
import { useEffect, useState } from 'react';
import type { PendingTaskNotificationProps } from '../types';
import { InlineWrapper, TaskButton, TaskNotification } from '../styles';
import { BoldText } from 'components/BoldText';
import { TaskListTypeMap } from 'mappings/enums';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';
import { TaskListStatus } from 'services/graphql';

export const PendingTaskNotification = ({
  task,
}: PendingTaskNotificationProps) => {
  const tasksDispatch = useTasksDispatch();
  const { taskDetails } = useTasks();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit, loading, error } = useAddOrUpdateTaskList(() =>
    tasksDispatch({
      type: 'resetTaskDetails',
    })
  );

  const dueDate = parse(task.dueDateTime, 'dd/MM/yy', new Date());
  const isDueToday = isToday(dueDate);

  const onCompleteTask = () => {
    tasksDispatch({
      type: 'setTaskDetails',
      taskId: task.id,
    });
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting && taskDetails?.id) {
      onSubmit({
        status: TaskListStatus.Complete,
      });
    }
  }, [isSubmitting, taskDetails]);

  useEffect(() => {
    if (isSubmitting && error) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, error]);

  return (
    <TaskNotification
      icon="Task"
      type={isDueToday ? 'informative' : 'softWarning'}
      data-testid={`${task.dueType}-pending-task-notification`}
    >
      <InlineWrapper>
        <span>
          {isDueToday ? (
            <>
              <BoldText>{`${TaskListTypeMap[task.type]}`}</BoldText> is due{' '}
              <BoldText>today.</BoldText>
            </>
          ) : (
            <>
              <BoldText>{`${TaskListTypeMap[task.type]}`}</BoldText> is{' '}
              <BoldText>overdue</BoldText> since {format(dueDate, 'dd/MM/yyyy')}
            </>
          )}
        </span>
        <TaskButton
          id="task-done-button"
          onClick={onCompleteTask}
          isLoading={loading || isSubmitting}
          disabled={loading || isSubmitting}
          isOverdue={!isDueToday}
        >
          Task done
        </TaskButton>
      </InlineWrapper>
    </TaskNotification>
  );
};
