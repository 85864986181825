import intervalToDuration from 'date-fns/intervalToDuration';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import { ConsultationTimeframe } from 'services/graphql';

export const getConsultationTimeframe = (date: string) => {
  const end = parse(date, 'dd/MM/yy', new Date());
  const {
    months = 0,
    weeks = 0,
    days = 0,
  } = intervalToDuration({ start: new Date(), end });

  const overdue = isBefore(end, new Date());
  if (overdue) return null;

  if (months >= 7) return ConsultationTimeframe.TwelveMonths;
  if (months >= 4) return ConsultationTimeframe.SixMonths;
  if (months >= 2) return ConsultationTimeframe.ThreeMonths;
  if (months === 1 || weeks >= 3 || days >= 27)
    return ConsultationTimeframe.OneMonth;
  if (weeks >= 2 || days >= 8) return ConsultationTimeframe.TwoWeeks;
  if (days >= 3) return ConsultationTimeframe.OneWeek;

  return ConsultationTimeframe.WithinTwoDays;
};
