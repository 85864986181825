import {
  Wrapper,
  Text,
  Divider,
  colors,
  fontSizes,
  Button,
  Checkbox,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';
import { TaskDueFilterMap } from '../TasksList/utils';
import type { FilterDropdownMenuProps } from '../types';
import { CheckboxWrapper, TasksFilterWrapper, TextWrapper } from './styles';

export const FilterDropDownMenu = ({
  statusFilter,
  handleFilterStatusSelect,
  clearFilter,
  handleTargetChecked,
}: FilterDropdownMenuProps) => {
  const totalFilters = statusFilter.length;

  return (
    <Wrapper className="dropdown" data-testid="dropdown-menu">
      <TextWrapper>
        <Text color={colors.grey.grey10} fontSize={fontSizes.h4}>
          Filter by
        </Text>
        {totalFilters > 0 && (
          <Wrapper
            onClick={clearFilter}
            className="clear-all"
            data-testid="status-filter-clear-all"
          >
            <Text
              color={colors.primaryIndigo.indigo08}
              fontSize={fontSizes.base}
              fontWeight={500}
            >
              Clear all
            </Text>
          </Wrapper>
        )}
      </TextWrapper>
      <Divider flexColor="grey.grey03" />
      <TasksFilterWrapper>
        <CheckboxWrapper data-testid="task-list-filters">
          {Object.keys(TaskDueFilterMap).map((filterKey) => (
            <Checkbox
              id={`tasks-${toHyphenCase(filterKey)}-filter`}
              label={TaskDueFilterMap[filterKey]}
              position="right"
              key={filterKey}
              className="accordion-item-checkbox"
              checked={
                statusFilter &&
                statusFilter.find((item) => item === filterKey) !== undefined
              }
              onChange={({ target }) => {
                handleTargetChecked(target, filterKey);
              }}
            />
          ))}
        </CheckboxWrapper>
      </TasksFilterWrapper>
      <Button
        id="apply-filter-button"
        onClick={() => {
          handleFilterStatusSelect(statusFilter);
        }}
      >
        Apply
        {totalFilters > 0 && <> ({totalFilters})</>}
      </Button>
    </Wrapper>
  );
};
