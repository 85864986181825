import { Text, colors, Grid } from '@bt-healthcare/ui-toolkit';

import type { TaskCardHeaderProps } from 'components/TaskList/types';
import { TaskDropdownMenu } from 'components/TaskList/TaskCard/TaskCardHeader/TaskDropdownMenu';

export const TaskCardHeader = ({ id, text }: TaskCardHeaderProps) => (
  <Grid
    style={{
      gridTemplateColumns: '1fr max-content',
      alignItems: 'center',
    }}
  >
    <Text color={colors.grey.grey10} fontWeight={500}>
      {text}
    </Text>
    <TaskDropdownMenu id={id} />
  </Grid>
);
