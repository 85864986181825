import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';
import type { UncompleteTaskTypes } from './utils';
import { UncompleteTaskDescMapper } from './utils';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';
import { TaskListStatus } from 'services/graphql';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const UncompleteTask = () => {
  const { showUncompleteTaskModal, taskDetails } = useTasks();
  const tasksDispatch = useTasksDispatch();
  const patientDispatch = usePatientDispatch();
  const desc = UncompleteTaskDescMapper?.[
    taskDetails?.type! as UncompleteTaskTypes
  ] ?? ['', ''];

  const handleClose = () => {
    tasksDispatch({
      type: 'resetShowUncompleteTaskModal',
    });
    patientDispatch({ type: 'reloadPatient', reload: true });
  };

  const { onSubmit, loading } = useAddOrUpdateTaskList(handleClose);

  return (
    <Modal
      onClose={handleClose}
      isModalOpen={showUncompleteTaskModal}
      id="uncomplete-task-modal"
    >
      <ModalBody>
        <TextWrapper>
          <ModalTitle title="This task will be marked as incomplete" />
          <ModalStrapline>
            This will change the <b>test status</b> from <b>{desc[0]}</b> to{' '}
            <b>{desc[1]}</b>.
          </ModalStrapline>
        </TextWrapper>
        <ButtonWrapper>
          <Button id="uncomplete-task-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id="uncomplete-task-proceed"
            onClick={() => onSubmit({ status: TaskListStatus.Pending })}
            variant="primaryRed"
            isLoading={loading}
          >
            Mark as incomplete
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
