import { device, spacing, Wrapper } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TaskBarWrapper = styled(Wrapper)`
  display: flex;
  gap: ${spacing.s4};
  flex-wrap: wrap;

  @media ${device.largeTablet} {
    gap: ${spacing.s6};
  }

  div:not(:nth-child(1)):not(:nth-child(2)) {
    display: none;
  }
`;
