import {
  BTHealthIcon,
  colors,
  useClickOutside,
} from '@bt-healthcare/ui-toolkit';
import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';

import type { TaskDropdownMenuProps } from '../../../types';
import {
  CustomWrapper,
  DropdownMenu,
  DropdownMenuItem,
  MoreButton,
} from './styles';
import { useTasksDispatch } from 'context/tasks/TasksContext';

export const TaskDropdownMenu = ({ id }: TaskDropdownMenuProps) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const tasksDispatch = useTasksDispatch();

  useClickOutside(dropdownRef, () => setOpen(false));

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleEditTask = () => {
    tasksDispatch({
      type: 'setTaskDetails',
      taskId: id,
    });
    tasksDispatch({
      type: 'setIsEditTask',
      isEditTask: true,
    });
    toggleMenu();
  };

  const handleDeleteTask = () => {
    tasksDispatch({
      type: 'setTaskDetails',
      taskId: id,
    });
    tasksDispatch({
      type: 'setShowDeleteTaskModal',
      showDeleteTaskModal: true,
    });
  };

  return (
    <CustomWrapper ref={dropdownRef}>
      <MoreButton
        id="btn-task-more"
        variant="tertiary"
        onClick={toggleMenu}
        open={open}
      >
        <BTHealthIcon
          icon="MoreHorizontal"
          color={open ? colors.primaryIndigo.indigo08 : colors.grey.grey08}
          size={16}
        />
      </MoreButton>
      {open && (
        <DropdownMenu data-testid="task-dropdown-menu">
          <ul>
            <DropdownMenuItem
              onClick={handleEditTask}
              data-testid="edit-task-btn"
            >
              <BTHealthIcon icon="EditDetails" color="currentColor" size={16} />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={handleDeleteTask}
              data-testid="delete-task-btn"
            >
              <BTHealthIcon
                icon="DeleteOutline"
                color="currentColor"
                size={16}
              />
              Delete
            </DropdownMenuItem>
          </ul>
        </DropdownMenu>
      )}
    </CustomWrapper>
  );
};
