import { v4 as uuidv4 } from 'uuid';
import { getEnvVariable } from 'env.utils';

export const HTTP_HEADER = {
  apigwTracking: 'APIGW-Tracking-Header',
  appVersion: 'x-app-version',
  appName: 'x-app-name',
};

export const getHttpHeaders = () => ({
  [HTTP_HEADER.apigwTracking]: uuidv4(),
  [HTTP_HEADER.appName]: getEnvVariable('VITE_APP_NAME'),
  [HTTP_HEADER.appVersion]: getEnvVariable('VITE_APP_VERSION'),
});
