import { Checkbox, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { CheckboxWrapper } from './styles';
import type { PatientStatusFiltersProps } from './types';
import { TaskListTypeMap } from 'mappings/enums';
import type { TaskListType } from 'services/graphql';
import type { SubFilter } from 'filters/types';

export const TaskDueFilters = ({
  statusFilter,
  handleTargetChecked,
}: PatientStatusFiltersProps) => {
  const taskDueFilters = Object.keys(TaskListTypeMap).map((item) => ({
    value: item,
    type: 'TaskDueType',
  })) as SubFilter[];

  return (
    <CheckboxWrapper data-testid="task-due-filters">
      {taskDueFilters.map((taskDue) => (
        <Checkbox
          id={`test-${toHyphenCase(taskDue.value)}-filter`}
          label={TaskListTypeMap[taskDue.value as TaskListType]}
          position="right"
          key={taskDue.value}
          className="accordion-item-checkbox"
          checked={
            statusFilter &&
            statusFilter.find(
              (item) =>
                item.value === taskDue.value && item.type === 'TaskDueType'
            ) !== undefined
          }
          onChange={({ target }) => handleTargetChecked(target, taskDue)}
        />
      ))}
    </CheckboxWrapper>
  );
};
