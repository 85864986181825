import {
  Text,
  StatusChip,
  fontSizes,
  Stack,
  SpinnerLoader,
} from '@bt-healthcare/ui-toolkit';

import { useEffect } from 'react';
import { SpinnerWrapper, TaskCheckbox } from './styles';
import type { TaskStatusChipProps } from 'components/TaskList/types';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';
import { TaskListStatus } from 'services/graphql';
import { TaskDueTypes } from 'components/TaskList/TasksList/utils';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { usePatientDispatch } from 'context/patient/PatientContext';
import { shouldShowUncompletePopup } from 'components/Modal/UncompleteTask/utils';

export const TaskStatusChip = ({
  id,
  chipColor,
  variant,
  textColor,
  text,
  date,
  type,
  disableCheckbox,
}: TaskStatusChipProps) => {
  const patientDispatch = usePatientDispatch();
  const { onSubmit, loading } = useAddOrUpdateTaskList(() =>
    patientDispatch({ type: 'reloadPatient', reload: true })
  );
  const {
    taskDetails,
    isCheckTask,
    currentTestStatus,
    currentConsultationStatus,
  } = useTasks();
  const tasksDispatch = useTasksDispatch();

  const handleOnChange = () => {
    if (
      variant === TaskDueTypes.COMPLETED &&
      shouldShowUncompletePopup(
        type,
        currentTestStatus,
        currentConsultationStatus
      )
    ) {
      tasksDispatch({
        type: 'setShowUncompleteTaskModal',
        taskId: id,
        showUncompleteTaskModal: true,
      });

      return;
    }

    tasksDispatch({
      type: 'setCheckTask',
      taskId: id,
      isCheckTask: true,
    });
  };

  useEffect(() => {
    if (taskDetails?.id === id && isCheckTask) {
      onSubmit({
        status:
          variant === TaskDueTypes.COMPLETED
            ? TaskListStatus.Pending
            : TaskListStatus.Complete,
      });
      tasksDispatch({
        type: 'resetCheckTask',
      });
    }
  }, [taskDetails, isCheckTask]);

  return (
    <StatusChip id={`status-chip-${id}`} chipColor={chipColor}>
      <Stack id="status-chip-stack" flexDirection="row">
        {loading && (
          <SpinnerWrapper data-testid={`status-chip-loader-${id}`}>
            <SpinnerLoader id="" data-testid="" />
          </SpinnerWrapper>
        )}

        {!disableCheckbox && !loading && (
          <TaskCheckbox
            id={`status-chip-checkbox-${id}`}
            $variant={variant}
            variant="secondary"
            onChange={handleOnChange}
            checked={variant === TaskDueTypes.COMPLETED}
          />
        )}
        <Text color={textColor} style={{ marginLeft: '2px' }}>
          <Text
            as="span"
            color="inherit"
            fontWeight={['overdue', 'today'].includes(variant) ? 500 : 400}
            fontSize={fontSizes.sm}
          >
            {text}
          </Text>{' '}
          {date}
        </Text>
      </Stack>
    </StatusChip>
  );
};
