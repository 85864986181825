import { format, isBefore, isToday, isTomorrow } from 'date-fns';
import { concat, flatten, partition, sort } from 'ramda';
import type { TaskAttributes } from '../types';
import type { TaskListAttributes } from 'services/graphql';
import { TaskListStatus, TaskListType } from 'services/graphql';
import { TaskListTypeMap } from 'mappings/enums';

export const TaskDueTypes = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  OVERDUE: 'overdue',
  FUTURE: 'future',
  COMPLETED: 'completed',
};

export const TaskDueFilterMap = {
  [TaskDueTypes.TODAY]: 'Due today',
  [TaskDueTypes.TOMORROW]: 'Due tomorrow',
  [TaskDueTypes.OVERDUE]: 'Overdue',
  [TaskDueTypes.COMPLETED]: 'Completed',
};

export const parseTaskDate = (date: string) =>
  format(new Date(date), 'dd/MM/yy');

export const parseTaskTime = (date: string) => format(new Date(date), 'HH:mm');

export const getTaskText = (task: TaskListAttributes) => {
  if (task.wardPatientTaskType === TaskListType.Other) {
    return task.wardPatientTaskTypeDetail;
  }

  return TaskListTypeMap[task.wardPatientTaskType!];
};

export const getTaskDueType = (date: string, status: TaskListStatus) => {
  const parsedDate = new Date(date);
  const overdue = isBefore(parsedDate, new Date());
  const today = isToday(parsedDate);
  const tomorrow = isTomorrow(parsedDate);
  const completed = status === TaskListStatus.Complete;

  if (completed) {
    return TaskDueTypes.COMPLETED;
  }

  if (today) {
    return TaskDueTypes.TODAY;
  }

  if (overdue) {
    return TaskDueTypes.OVERDUE;
  }

  if (tomorrow) {
    return TaskDueTypes.TOMORROW;
  }

  return TaskDueTypes.FUTURE;
};

export const disableLastCompletedTask = (
  status: TaskListStatus,
  index: number,
  length: number
) => status === TaskListStatus.Complete && index !== length - 1;

export const sortByNewestLastUpdatedTime = (tasks: TaskAttributes[]) =>
  sort(
    (a, b) =>
      b.lastUpdatedDate.localeCompare(a.lastUpdatedDate) ||
      b.lastUpdatedTime.localeCompare(a.lastUpdatedTime),
    tasks
  );

export const sortCompletedTasksByLatestDueDate = (tasks: TaskAttributes[]) => {
  const partitionedTasks = partition(
    (task) => task.status === TaskListStatus.Complete,
    tasks
  );
  const reversedCompletedTasks = [...partitionedTasks[0]].reverse();
  const overdueTasks = partitionedTasks[1].filter(
    (x) => x.dueType === TaskDueTypes.OVERDUE
  );
  const todayTasks = partitionedTasks[1].filter(
    (x) => x.dueType === TaskDueTypes.TODAY
  );
  const futureTasks = partitionedTasks[1].filter((x) =>
    [TaskDueTypes.TOMORROW, TaskDueTypes.FUTURE].includes(x.dueType)
  );

  const remainingTasks = flatten([
    sortByNewestLastUpdatedTime(overdueTasks),
    sortByNewestLastUpdatedTime(todayTasks),
    sortByNewestLastUpdatedTime(futureTasks),
  ]);

  return concat(remainingTasks, reversedCompletedTasks);
};

export const buildTasksListUI = (tasks: any) => {
  const tasksListUI = tasks?.map(({ id, attributes }: any, index: number) => ({
    id,
    personId: attributes.personId!,
    wardPatientId: attributes.wardPatientId!,
    dueDateTime: parseTaskDate(attributes.dueDateTime!),
    dueType: getTaskDueType(
      attributes.dueDateTime!,
      attributes.wardPatientTaskStatus!
    ),
    isDeleted: attributes.isDeleted!,
    type: attributes.wardPatientTaskType!,
    status: attributes.wardPatientTaskStatus!,
    text: getTaskText(attributes)!,
    taskContext: attributes.taskContext,
    version: attributes.audit?.version!,
    lastUpdatedDate: parseTaskDate(attributes.audit?.lastUpdated!),
    lastUpdatedTime: parseTaskTime(attributes.audit?.lastUpdated!),
    updatedBy:
      attributes.audit?.updatedByUser?.careSettingClinician?.attributes
        .displayName!,
    disableCheckbox: disableLastCompletedTask(
      attributes.wardPatientTaskStatus!,
      index,
      tasks.length
    ),
    created: attributes.audit?.created,
  }));

  return sortCompletedTasksByLatestDueDate(tasksListUI);
};

export const filterTasks = (tasks: any, filters: string[]) =>
  tasks.filter((task: TaskAttributes) => filters.includes(task.dueType));
