import {
  Button,
  colors,
  fontSizes,
  spacing,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CustomWrapper = styled(Wrapper)`
  position: relative;
`;

export const MoreButton = styled(Button)<{ open: boolean }>`
  width: 24px;
  height: 24px;
  min-height: 0px;
  padding: 0;
  background-color: ${({ open }) =>
    open ? colors.primaryIndigo.indigo01 : colors.base.white};
  cursor: pointer;
  border-radius: 2px;
  padding: ${spacing.s1};
  &:hover {
    background-color: ${colors.primaryIndigo.indigo01};
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.s1});
  right: 0;
  z-index: 2;
  box-shadow: 0px 8px 30px rgba(112, 144, 176, 0.2);
  border-radius: ${spacing.s1};
  background: ${colors.base.white};
  width: 130px;

  ul {
    list-style: none;
    padding: ${spacing.s3} ${spacing.s2};
    margin: ${spacing.none};
  }

  li {
    cursor: pointer;
  }
`;

export const DropdownMenuItem = styled.li`
  margin-bottom: ${spacing.s1};
  color: ${colors.grey.grey09};
  display: flex;
  flex-direction: row;
  border-radius: ${spacing.s1};
  gap: ${spacing.s2};
  align-items: center;
  font-size: ${fontSizes.xs};
  padding: ${spacing.s2} ${spacing.s3};

  &:hover {
    color: ${colors.primaryIndigo.indigo08};
    background-color: ${colors.primaryIndigo.indigo01};
  }

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
