import * as yup from 'yup';
import { isRequiredNullable } from 'validation/yup/schema.helpers';

export const schema = () =>
  yup.object().shape({
    taskDueDate: isRequiredNullable(yup.string()),
    task: yup.object().nullable().required('This field is required.'),
    other: yup.string().when('task', {
      is: (val: any) => val?.value === 'OTHER',
      then: () => yup.string().required('This field is required.'),
      otherwise: () => yup.string().nullable(),
    }),
  });
