import { Button, colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { StateProps } from 'components/PatientListFilter/types';

export const FilterButton = styled(Button)<{
  $selected: boolean;
  $active: boolean;
}>`
  width: 35px;
  height: 35px;
  min-height: 0;
  padding: 4px;

  border-radius: 8px;

  ${(props) =>
    props.$selected || props.$active
      ? `border: 1px solid ${colors.primaryIndigo.indigo08};`
      : ''}

  ${(props) =>
    props.$selected ? `background: ${colors.primaryIndigo.indigo01};` : ''}
`;

export const FilterDropdownWrapper = styled.div<StateProps>`
  z-index: ${({ selected }) => selected && 2};
  position: relative;

  .dropdown {
    background-color: ${colors.base.white};
    border: 1px solid ${colors.grey.grey05};
    border-radius: ${spacing.s4};
    padding: ${spacing.s6};
  }
`;

export const DropdownWrapper = styled.div`
  z-index: 10;

  position: absolute;
  right: 0;
  width: 270px;
`;

export const Spacer = styled.hr`
  border: 0;
  display: block;
  margin: ${spacing.s1};
`;
