import { localDateTimeToZonedDateTimeConvertor } from '@bt-healthcare/ui-toolkit';
import type { UseFormResetField, UseFormSetValue } from 'react-hook-form';

import type { MutableRefObject } from 'react';
import add from 'date-fns/add';
import { EMPTY_VALUE, QRISK_VERSION } from 'App.constants';
import type {
  HypertensionAssessmentFormData,
  HypertensionConsultationRecommendedFormData,
} from 'components/HypertensionAssessmentForm/types';
import type { MonitoredConditionView } from 'models/wardPatient/types';
import { ConsultationTimeframe, PatientDiagnosis } from 'services/graphql';
import type {
  YesNoType,
  Maybe,
  WardPatientConditionAssessmentInput,
} from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';
import { READING_RANGE } from 'models/wardPatientObservation';
import { YesNoMap } from 'mappings/enums';

export const getWardPatientConditionAssessmentInput = (
  formData: HypertensionAssessmentFormData,
  wardPatientId: string,
  monitoredCondition = PatientDiagnosis.Hypertension
) => {
  const guidelineRecommendationDecisions =
    formData.guidelineRecommendationDecisions?.map((guideline) => ({
      ...guideline,
      recommendationDeclinedReason:
        guideline?.recommendationDeclinedReason?.value ?? null,
    }));

  return {
    assessmentComments: formData.assessmentComments,
    assessmentType: formData.assessmentType.value,
    assessmentStartDateZoned: localDateTimeToZonedDateTimeConvertor(
      formData.assessmentStartDate.toString()
    ),
    monitoredCondition,
    wardPatientId,
    hypertensionAssessment: {
      qriskScoreVersion: QRISK_VERSION,
      qriskScore: isEmptyOrNil(formData.qriskScore)
        ? null
        : +formData.qriskScore!,
      averageDiastolic: formData.averageDiastolic,
      averageSystolic: formData.averageSystolic,
      totalDays: formData.totalDays,
      totalReadings: formData.totalReadings,
      fitToSelfMonitorYN: formData.fitToSelfMonitorYN,
      testsRequiredYN: formData.testsRequiredYN,
      conditionTestTypes:
        formData.conditionTestTypes?.map((item) => item.value) ?? null,
      conditionTestTypeOther: formData.conditionTestTypeOther,
      consultationRequiredYN: formData.consultationRequiredYN,
      consultationTimeframe: formData.consultationTimeframe?.value ?? null,
      customConsultationTimeframe: formData.customConsultationTimeframe,
      urgentClinicalInterventionRequiredYN:
        formData.urgentClinicalInterventionRequiredYN,
      urgentClinicalInterventionTakenPlaceYN:
        formData.urgentClinicalInterventionTakenPlaceYN,
      reasonForNoUrgentClinicalIntervention:
        formData.reasonForNoUrgentClinicalIntervention,
    },
    medicationAssessment: {
      medicationProtocol: formData.medicationProtocol?.value ?? null,
      isTakingBloodPressureMedicationYN:
        formData.isTakingBloodPressureMedicationYN,
      hasEverTakenBloodPressureMedicationYN:
        formData.hasEverTakenBloodPressureMedicationYN,
      newMedicationProtocol: formData.newMedicationProtocol?.value ?? null,
      comments: formData.comments,
      stoppedMedicationOutcomeComments:
        formData.stoppedMedicationOutcomeComments,
      medicationOutcome: formData.medicationOutcome?.value ?? null,
      guidelineRecommendationDecisions,
    },
  } as WardPatientConditionAssessmentInput;
};

export const getCurrentHypertensionStatus = (
  statusFieldName: 'testStatus' | 'consultationStatus' | 'monitoringStatus',
  monitoredConditions?: MonitoredConditionView
) =>
  monitoredConditions?.find(
    (cond) => cond.condition === PatientDiagnosis.Hypertension
  )?.[statusFieldName]?.status ?? undefined;

export const resetConsultationFormFields = (
  resetField: UseFormResetField<HypertensionAssessmentFormData>
) => {
  resetField('comments');
  resetField('guidelineRecommendationDecisions');
  resetField('medicationOutcome');
  resetField('newMedicationProtocol');
  resetField('stoppedMedicationOutcomeComments');
};

export const validateRecommendationFieldValues = (
  averageDiastolic: number,
  averageSystolic: number,
  totalDays: number,
  totalReadings: number
) =>
  Number.isInteger(averageDiastolic) &&
  averageDiastolic >= READING_RANGE.diastolic.min &&
  Number.isInteger(averageSystolic) &&
  averageSystolic >= READING_RANGE.systolic.min &&
  averageSystolic <= READING_RANGE.systolic.max &&
  Number.isInteger(totalDays) &&
  totalDays > 0 &&
  Number.isInteger(totalReadings) &&
  totalReadings > 0 &&
  averageSystolic > averageDiastolic;

export const setPreviousConsultationRecommendedValues = (
  setValue: UseFormSetValue<HypertensionAssessmentFormData>,
  previousRecommendationRef: MutableRefObject<
    HypertensionConsultationRecommendedFormData | undefined
  >
) => {
  if (previousRecommendationRef.current) {
    setValue(
      'averageDiastolic',
      previousRecommendationRef.current.averageDiastolic
    );
    setValue(
      'averageSystolic',
      previousRecommendationRef.current.averageSystolic
    );
    setValue('totalDays', previousRecommendationRef.current.totalDays);
    setValue('totalReadings', previousRecommendationRef.current.totalReadings);
  }
};

export const handleNavigateBack = (
  previousRecommendationRef: MutableRefObject<
    HypertensionConsultationRecommendedFormData | undefined
  >,
  hypertensionAssessmentFormData: HypertensionAssessmentFormData,
  recommendationTriggeredRef: MutableRefObject<boolean>
) => {
  // eslint-disable-next-line no-param-reassign
  recommendationTriggeredRef.current = true;
  const {
    averageDiastolic,
    averageSystolic,
    totalDays: previousDays,
    totalReadings: previousReadings,
  } = hypertensionAssessmentFormData;
  // eslint-disable-next-line no-param-reassign
  previousRecommendationRef.current = {
    averageDiastolic,
    averageSystolic,
    totalDays: previousDays,
    totalReadings: previousReadings,
  };
};

export const getFormValue = (formValue: any) =>
  isEmptyOrNil(formValue) ? EMPTY_VALUE : formValue;

export const getFormYesNoValue = (
  formValue: string | Maybe<YesNoType> | undefined
) => (isEmptyOrNil(formValue) ? EMPTY_VALUE : YesNoMap[formValue as YesNoType]);

export const getConsultationTimeframeDate = (value: ConsultationTimeframe) => {
  const durationMap = {
    [ConsultationTimeframe.WithinTwoDays]: { days: 2 },
    [ConsultationTimeframe.OneWeek]: { days: 7 },
    [ConsultationTimeframe.TwoWeeks]: { days: 14 },
    [ConsultationTimeframe.OneMonth]: { months: 1 },
    [ConsultationTimeframe.ThreeMonths]: { months: 3 },
    [ConsultationTimeframe.SixMonths]: { months: 6 },
    [ConsultationTimeframe.TwelveMonths]: { months: 12 },
    // for other, make the date invalid so the clinician need to select a new date
    [ConsultationTimeframe.Other]: { days: -2 },
  };

  const duration =
    durationMap[value] || durationMap[ConsultationTimeframe.Other];
  return add(new Date(), duration);
};
