import type { ColorType } from '@bt-healthcare/ui-toolkit';
import { colors } from '@bt-healthcare/ui-toolkit';
import { parse } from 'date-fns';
import {
  getTaskDueType,
  TaskDueTypes,
} from 'components/TaskList/TasksList/utils';
import type {
  StatusChipVariant,
  TaskAttributes,
} from 'components/TaskList/types';

export const ChipColorMapper = {
  [TaskDueTypes.TODAY]: 'secondaryBlue.blue01',
  [TaskDueTypes.OVERDUE]: 'notifications.light.errorLight',
  [TaskDueTypes.COMPLETED]: 'notifications.light.successLight',
  [TaskDueTypes.FUTURE]: 'grey.grey02',
  [TaskDueTypes.TOMORROW]: 'grey.grey02',
};

export const TextColorMapper = {
  [TaskDueTypes.TODAY]: colors.secondaryBlue.blue06,
  [TaskDueTypes.OVERDUE]: colors.notifications.light.errorDark,
  [TaskDueTypes.COMPLETED]: colors.grey.grey09,
  [TaskDueTypes.FUTURE]: colors.grey.grey09,
  [TaskDueTypes.TOMORROW]: colors.grey.grey09,
};

export const TextMapper = {
  [TaskDueTypes.TODAY]: 'Due today',
  [TaskDueTypes.OVERDUE]: 'Overdue',
  [TaskDueTypes.COMPLETED]: 'Completed',
  [TaskDueTypes.TOMORROW]: 'Due',
  [TaskDueTypes.FUTURE]: 'Due',
};

export const buildStatusChipUI = ({
  id,
  status,
  dueDateTime,
  disableCheckbox,
  type,
}: TaskAttributes) => {
  const taskType = getTaskDueType(
    parse(dueDateTime, 'dd/MM/yy', new Date()).toDateString(),
    status
  );

  return {
    id,
    chipColor: ChipColorMapper[taskType] as ColorType,
    variant: taskType as StatusChipVariant,
    textColor: TextColorMapper[taskType],
    text: TextMapper[taskType],
    date: dueDateTime,
    type,
    disableCheckbox,
  };
};
