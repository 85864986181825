import {
  Text,
  colors,
  Card,
  fontSizes,
  Stack,
  Spacer,
} from '@bt-healthcare/ui-toolkit';

import type { TaskCardProps } from '../types';
import { TaskStatusChip } from './TaskStatusChip';
import { buildStatusChipUI } from './TaskStatusChip/utils';
import { TaskCardHeader } from './TaskCardHeader';

export const TaskCard = ({ task }: TaskCardProps) => (
  <Card
    borderColor={colors.grey.grey05}
    id="task-card"
    borderRadius={8}
    isListCard
  >
    <Stack id="task-card-stack">
      <TaskCardHeader id={task.id} text={task.text} />
      <Spacer size="s4" />
      <TaskStatusChip {...buildStatusChipUI(task)} />
      <Spacer size="s2" />
      <Text fontSize={fontSizes.xs} color={colors.grey.grey07}>
        Last updated: {task.lastUpdatedDate} at {task.lastUpdatedTime}{' '}
        {task.updatedBy ? `by ${task.updatedBy}` : ''}
      </Text>
    </Stack>
  </Card>
);
