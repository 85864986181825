import { Outlet } from 'react-router-dom';

import { PatientProvider } from 'context/patient/PatientProvider';
import { AdmissionProvider } from 'context/admission/AdmissionProvider';
import { TasksProvider } from 'context/tasks/TasksProvider';

export const PatientLayout = () => (
  <PatientProvider>
    <AdmissionProvider>
      <TasksProvider>
        <Outlet />
      </TasksProvider>
    </AdmissionProvider>
  </PatientProvider>
);
