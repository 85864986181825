import type {
  AdmissionStatus,
  GetWardPatientsQuery,
  Maybe,
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
  MonitoringStatusType,
  PatientDiagnosis,
  WardPatientsSortKey,
} from 'services/graphql';

export enum PatientListColumn {
  Patient = 'Patient',
  DOB = 'DOB',
  MedicalCentre = 'MedicalCentre',
  Monitored = 'Monitored',
  LastReview = 'LastReview',
  PatientStatus = 'PatientStatus',
  ConsultationStatus = 'ConsultationStatus',
  TestStatus = 'TestStatus',
  NextTaskDue = 'NextTaskDue',
}

export type SortParams<T> = {
  ascending: Maybe<boolean>;
  key: Maybe<T>;
};

export type PatientListRendererProps = {
  loading: boolean;
  hasMore: boolean;
  data?: GetWardPatientsQuery;
  sortParams: Maybe<SortParams<WardPatientsSortKey>>;
  setSortingQuery: (sortParams: SortParams<WardPatientsSortKey>) => void;
  loadNext: () => void;
  patientListCount: number;
};

export type AdmissionStatusChipProps = {
  admissionStatus: Maybe<AdmissionStatus>;
  id: string;
};

type MonitoredConditionStatusMapping<T> = {
  [Key in PatientDiagnosis]?: T;
};

type StatusChipProps<
  T extends
    | MonitoringStatusType
    | MonitoredConditionConsultationStatusType
    | MonitoredConditionTestStatusType
> = {
  id: string;
} & {
  [key in T extends MonitoringStatusType
    ? 'monitoringStatusByCondition'
    : T extends MonitoredConditionConsultationStatusType
    ? 'consultationStatusByCondition'
    : T extends MonitoredConditionTestStatusType
    ? 'testStatusByCondition'
    : never]: MonitoredConditionStatusMapping<T>;
} & {
  [key in T extends
    | MonitoredConditionConsultationStatusType
    | MonitoredConditionTestStatusType
    ? 'monitoringStatusByCondition'
    : never]: MonitoredConditionStatusMapping<MonitoringStatusType>;
};

type StatusRendererProps<T> = {
  id: string;
  items: Array<{ condition: string; statusType: T | null }>;
};

export type MonitoringStatusChipProps = StatusChipProps<MonitoringStatusType>;
export type ConsultationStatusChipProps =
  StatusChipProps<MonitoredConditionConsultationStatusType>;
export type TestStatusChipProps =
  StatusChipProps<MonitoredConditionTestStatusType>;

export type MonitoringStatusRendererProps =
  StatusRendererProps<MonitoringStatusType>;
export type ConsultationStatusRendererProps =
  StatusRendererProps<MonitoredConditionConsultationStatusType>;
export type TestStatusRendererProps =
  StatusRendererProps<MonitoredConditionTestStatusType>;
