import { colors, device, spacing, Wrapper } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TasksFilterWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.s4};
  margin: ${spacing.s5} 0;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  max-height: 388px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    border: 1px solid ${colors.grey.grey05};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${colors.grey.grey05};

    &:hover {
      background: ${colors.primaryIndigo.indigo09};
    }
  }

  & > div {
    width: 96%;
    margin-bottom: ${spacing.s4};
    &:last-child {
      margin-bottom: ${spacing.none};
    }
  }

  @media ${device.largeTablet} {
    max-height: 280px;

    & > div {
      width: 214px;
    }
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: ${spacing.s3};
  display: flex;
  justify-content: space-between;

  .clear-all {
    cursor: pointer;
    display: none;

    @media ${device.largeTablet} {
      display: block;
    }
  }
`;
