import { prop } from 'ramda';

import {
  Text,
  colors,
  Timestamp,
  noop,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import type {
  ListViewItem,
  ListView,
  ListViewRow,
} from '@bt-healthcare/ui-toolkit';
import type { ComponentProps } from 'react';

import { AdmissionStatusChip } from './StatusChip/AdmissionStatusChip';
import { MonitoringStatusChip } from './StatusChip/MonitoringStatusChip';
import type { SortParams } from './PatientList.types';
import { PatientListColumn } from './PatientList.types';
import { ConsultationStatusChip } from './StatusChip/ConsultationStatusChip';
import { TestStatusChip } from './StatusChip/TestStatusChip';
import {
  MedicalPracticeTextWrapper,
  MonitoredConditionRendererWrapper,
} from './PatientList.styles';
import { NextTaskDue } from './NextTaskDue/NextTaskDue';
import { EMPTY_VALUE } from 'App.constants';
import type {
  Maybe,
  TaskListType,
  WardPatientSearchRecord,
} from 'services/graphql';
import { AdmissionStatus, WardPatientsSortKey } from 'services/graphql';
import { formatMonitoredConditions } from 'mappings/monitoredConditions.formatter';

import { getDate } from 'utils/date.utils';
import { ColumnSort } from 'components/ColumnSort';

export const columnWidths = (showTaskDue: boolean = false) => ({
  desktop: `1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr${showTaskDue ? ' 1fr' : ''}`,
  mobile: '1fr 1fr 1fr',
});

export const headerDefaultData = (
  setSortingQuery: (sortParams: SortParams<WardPatientsSortKey>) => void,
  sortParams: Maybe<SortParams<WardPatientsSortKey>>
) =>
  [
    {
      id: PatientListColumn.Patient,
      item: (
        <ColumnSort
          setSortingQuery={setSortingQuery}
          sortKey={WardPatientsSortKey.PatientName}
          label="Patient"
          sortParams={sortParams}
        />
      ),
    },
    { id: PatientListColumn.DOB, label: 'DOB', hide: 'mobile' },
    {
      id: PatientListColumn.LastReview,
      item: (
        <ColumnSort
          setSortingQuery={setSortingQuery}
          sortKey={WardPatientsSortKey.LastReviewDatetime}
          label="Last reviewed"
          sortParams={sortParams}
        />
      ),
    },
    {
      id: PatientListColumn.MedicalCentre,
      item: (
        <ColumnSort
          setSortingQuery={setSortingQuery}
          sortKey={WardPatientsSortKey.MedicalCentre}
          label="Medical Centre"
          sortParams={sortParams}
        />
      ),
      hide: 'mobile',
    },
    {
      id: PatientListColumn.Monitored,
      label: 'Monitored condition(s)',
      hide: 'mobile',
    },
  ] as ComponentProps<typeof ListView>['headerData'];

export const headerData = (
  setSortingQuery: (sortParams: SortParams<WardPatientsSortKey>) => void,
  sortParams: Maybe<SortParams<WardPatientsSortKey>>,
  colsToHide: PatientListColumn[] = []
) =>
  [
    ...headerDefaultData(setSortingQuery, sortParams),
    { id: PatientListColumn.PatientStatus, label: 'Patient status' },
    {
      id: PatientListColumn.ConsultationStatus,
      label: 'Consultation',
      hide: 'mobile',
    },
    { id: PatientListColumn.TestStatus, label: 'Tests', hide: 'mobile' },
    {
      id: PatientListColumn.NextTaskDue,
      item: (
        <ColumnSort
          setSortingQuery={setSortingQuery}
          sortKey={WardPatientsSortKey.NextTaskDueTime}
          label="Next task due"
          sortParams={sortParams}
        />
      ),
      hide: 'mobile',
    },
  ].filter(
    (data) => !colsToHide.includes(data.id as PatientListColumn)
  ) as ComponentProps<typeof ListView>['headerData'];

export const toRows = (
  wardPatients: WardPatientSearchRecord[],
  colsToHide: PatientListColumn[] = []
): ListViewRow[] =>
  wardPatients.map(
    (
      {
        id,
        surname,
        firstName,
        dateOfBirth,
        registeredGPSurgery,
        lastReviewedDateTime,
        monitoredConditions,
        admissionStatus,
        monitoringStatusByCondition,
        consultationStatusByCondition,
        testStatusByCondition,
        nextTaskDueDateTime,
        nextTaskDueType,
        nextTaskDueTypeDetail,
        nextTaskDueStatus,
      },
      rootIdx: number
    ) => ({
      id,
      items: [
        {
          id: PatientListColumn.Patient,
          item: (
            <Text
              color={colors.primaryIndigo.indigo08}
              fontWeight={500}
              data-testid={`${id}-patient`}
            >
              {firstName} {surname}
            </Text>
          ),
        },
        {
          id: PatientListColumn.DOB,
          item: getDate(dateOfBirth),
          hide: 'mobile',
        },
        {
          id: PatientListColumn.LastReview,
          item: lastReviewedDateTime ? (
            <Timestamp date={new Date(lastReviewedDateTime)} type="dateTime" />
          ) : (
            EMPTY_VALUE
          ),
        },
        {
          id: PatientListColumn.MedicalCentre,
          item: (
            <MedicalPracticeTextWrapper>
              {registeredGPSurgery ?? EMPTY_VALUE}
            </MedicalPracticeTextWrapper>
          ),
          hide: 'mobile',
        },
        {
          id: PatientListColumn.Monitored,
          item: (
            <MonitoredConditionRendererWrapper
              data-testid={`conditions-wrapper-${rootIdx}`}
            >
              {formatMonitoredConditions(monitoredConditions)?.map(
                (item, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Wrapper key={idx}>{item}</Wrapper>
                )
              )}
            </MonitoredConditionRendererWrapper>
          ),
          hide: 'mobile',
        },
        {
          id: PatientListColumn.PatientStatus,
          item:
            admissionStatus !== AdmissionStatus.Monitoring ? (
              <AdmissionStatusChip admissionStatus={admissionStatus} id={id} />
            ) : (
              <MonitoringStatusChip
                id={id}
                monitoringStatusByCondition={monitoringStatusByCondition}
              />
            ),
        },
        {
          id: PatientListColumn.ConsultationStatus,
          item: (
            <ConsultationStatusChip
              id={id}
              consultationStatusByCondition={consultationStatusByCondition}
              monitoringStatusByCondition={monitoringStatusByCondition}
            />
          ),
          hide: 'mobile',
        },
        {
          id: PatientListColumn.TestStatus,
          item: (
            <TestStatusChip
              id={id}
              testStatusByCondition={testStatusByCondition}
              monitoringStatusByCondition={monitoringStatusByCondition}
            />
          ),
          hide: 'mobile',
        },
        {
          id: PatientListColumn.NextTaskDue,
          item: (
            <NextTaskDue
              nextTaskDueDateTime={nextTaskDueDateTime}
              nextTaskDueType={nextTaskDueType as TaskListType}
              nextTaskDueTypeDetail={nextTaskDueTypeDetail}
              nextTaskDueStatus={nextTaskDueStatus}
            />
          ),
          hide: 'mobile',
          overflow: false,
        },
      ]
        .filter((data) => !colsToHide.includes(data.id))
        .filter((data) =>
          headerData(noop, {
            ascending: true,
            key: WardPatientsSortKey.LastReviewDatetime,
          })
            .map(prop('id'))
            .includes(data.id)
        ) as ListViewItem[],
    })
  );
