import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { DatePickerWrapper } from 'components/DateRangePicker/styles';

export const TaskDueDateWrapper = styled(DatePickerWrapper)`
  position: relative;

  .react-datepicker-popper,
  .react-datepicker {
    width: 100%;
  }

  .react-datepicker {
    border-radius: ${spacing.s2};
    border-color: ${colors.grey.grey03};
  }

  .react-datepicker__month-container {
    float: none;
  }
`;
