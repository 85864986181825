import { Checkbox, colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { StatusChipVariant } from 'components/TaskList/types';

const CheckboxVariants = {
  overdue: colors.notifications.light.errorDark,
  today: colors.secondaryBlue.blue06,
  future: colors.grey.grey09,
  completed: colors.notifications.light.successDark,
};

export const TaskCheckbox = styled(Checkbox)<{
  $variant: StatusChipVariant;
}>`
  margin-left: 8px;
  margin-right: 2px;
  background: transparent;
  border-color: ${colors.grey.grey08};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    outline: none !important;
    border: 2px solid ${(props) => CheckboxVariants[props.$variant]};
  }

  &:hover:not(:disabled):not(:checked) {
    background: transparent !important;
  }
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 4px;

  > div {
    position: absolute;
    width: 24px;
    height: 24px;

    #-spinner {
      width: 18px;
      height: 18px;
    }

    #-card {
      padding: 0;
    }
  }
`;
