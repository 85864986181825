import {
  Button,
  colors,
  fontSizes,
  Modal,
  noop,
  Text,
} from '@bt-healthcare/ui-toolkit';

import Hero from 'assets/patient-admitted-success.svg?react';
import { useNavigate } from 'react-router-dom';
import type { ConfirmAssessmentProps } from '../types';
import { ButtonWrapper, ModalBody } from '../styles';
import { TextWrapper } from './styles';
import { ROUTE } from 'config/routes';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const ConfirmAssessment = ({
  modalOpen,
  wardPatientId,
}: ConfirmAssessmentProps) => {
  const navigate = useNavigate();
  const patientDispatch = usePatientDispatch();

  const handleNavigation = (route: string) => {
    patientDispatch({ type: 'resetHypertensionAssessmentFormData' });
    if (route === ROUTE.PATIENT_DETAIL) {
      navigate(route, { state: { wardPatientId } });
    } else {
      navigate(route);
    }
  };

  return (
    <Modal onClose={noop} isModalOpen={modalOpen} id="confirm-assessment-modal">
      <ModalBody>
        <Hero />
        <TextWrapper>
          <Text
            as="h2"
            fontSize={fontSizes.h3}
            fontWeight={500}
            color={colors.grey.grey10}
          >
            Assessment completed
          </Text>
          <Text fontWeight={400} color={colors.grey.grey08}>
            You can now return to the patient’s profile or view the patient list
            using the buttons below.
          </Text>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            id="view-patient-list-btn"
            onClick={() => handleNavigation(ROUTE.HOME)}
            variant="secondary"
          >
            View patient list
          </Button>
          <Button
            id="return-patient-profile-btn"
            onClick={() => handleNavigation(ROUTE.PATIENT_DETAIL)}
          >
            Return to profile
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
