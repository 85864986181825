import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import { TasksContext, TasksDispatchContext } from './TasksContext';
import type { TasksState } from 'reducers/tasks/types';
import { initTasksState, tasksReducer } from 'reducers/tasks/tasksReducer';

export const TasksProvider = ({
  children,
  value = initTasksState,
}: PropsWithChildren<{ value?: TasksState }>) => {
  const [state, dispatch] = useReducer(tasksReducer, value);
  return (
    <TasksContext.Provider value={state}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksContext.Provider>
  );
};
