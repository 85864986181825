import { colors } from '@bt-healthcare/ui-toolkit';

import { isBefore, isToday, parse } from 'date-fns';
import { TaskListTypeMap } from 'mappings/enums';
import { TaskListType } from 'services/graphql';

export const getTaskColor = (date: string) => {
  const parsedDate = parse(date, 'dd/MM/yy', new Date());
  const overdue = isBefore(parsedDate, new Date());
  const today = isToday(parsedDate);

  if (today) {
    return colors.secondaryBlue.blue06;
  }

  if (overdue) {
    return colors.rag.light.red;
  }

  return colors.grey.grey08;
};

export const getTaskText = (type: TaskListType, taskDetail: string) => {
  if (type === TaskListType.Other) {
    return taskDetail;
  }

  return TaskListTypeMap[type];
};
