import { useEffect, useState } from 'react';

import {
  SpinnerLoader,
  MenuBar,
  ErrorNotification,
  Grid,
  Row,
  Col,
} from '@bt-healthcare/ui-toolkit';
import { useNavigate } from 'react-router-dom';

import { MenuBarWrapper, PatientWrapper } from './styles';
import { initPatientMenuOptions, patientColumnMap } from './utils';
import type { WardPatient } from 'services/graphql';
import { ObservationType, useGetWardPatientQuery } from 'services/graphql';
import {
  buildPatientHeaderUI,
  buildWardPatientUI,
} from 'models/wardPatient/wardPatient.view';
import { useTracking } from 'hooks/useTracking';
import { PatientHeader } from 'components/PatientHeader/PatientHeader';
import { SideMenu } from 'components/SideMenu';
import { Notes } from 'components/Notes/Notes';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { DEFAULT_DATE_FILTER, FETCH_POLICY } from 'App.constants';
import { useApp } from 'context/app/AppContext';
import { PatientHealthVitals } from 'components/PatientHealthVitals';
import { PatientOverview } from 'components/PatientOverview';
import { ROUTE } from 'config/routes';
import { PageName } from 'config/pageNames';
import { useAssessmentTracking } from 'hooks/useAssessmentTracking';
import { PatientLifestyle } from 'components/PatientLifestyle';
import { PatientHealthInformation } from 'components/PatientHealthInformation';
import { PatientHistoricAssessments } from 'components/PatientHistoricAssessments';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import { checkIfAssessmentStart } from 'models/wardPatient/wardPatient.utils';
import { TaskList } from 'components/TaskList';
import { useTasks } from 'context/tasks/TasksContext';
import { DeleteTask } from 'components/Modal/DeleteTask';
import { UncompleteTask } from 'components/Modal/UncompleteTask';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { TaskBar } from 'components/TaskBar';

export const Patient = () => {
  const wardPatientId = useRedirectAndReturnState('wardPatientId');
  const navigate = useNavigate();
  const { careSetting } = useApp();
  const { reloadPatient } = usePatient();
  const patientDispatch = usePatientDispatch();
  const { isTaskListFeatureFlagEnabled } = useFeatureFlags();
  const [patientMenuOptions, setPatientMenuOptions] = useState(
    initPatientMenuOptions()
  );
  const [columns, setColumns] = useState(patientColumnMap[0]);
  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const [showTaskListPanel, setShowTaskListPanel] = useState(false);

  const { trackPage } = useTracking();
  const { trackTappedAssessment } = useAssessmentTracking();
  const { showDeleteTaskModal, showUncompleteTaskModal } = useTasks();
  const [isInit, setIsInit] = useState<boolean>(true);
  const shouldShowTaskList =
    showTaskListPanel && !showDeleteTaskModal && !showUncompleteTaskModal;

  useEffect(() => {
    trackPage(PageName.PATIENT_DETAIL);
    patientDispatch({ type: 'resetHypertensionAssessmentFormData' });
    patientDispatch({
      type: 'setChartDateFilter',
      synchedDateFilter: DEFAULT_DATE_FILTER,
    });
    patientDispatch({ type: 'resetChartDateRange' });
  }, []);

  const { data, loading, error, refetch } = useGetWardPatientQuery({
    variables: { wardPatientId, careSettingId: careSetting.id ?? null },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data !== undefined) {
      setIsInit(false);
    }
  }, [data]);

  useEffect(() => {
    if (reloadPatient) {
      refetch();
      patientDispatch({ type: 'reloadPatient', reload: false });
    }
  }, [reloadPatient]);

  const handleMenuClick = (label: string) => {
    const menuOptions = patientMenuOptions.map((item) => ({
      ...item,
      active: item.label === label,
    }));
    const idx = menuOptions.findIndex((item) => item.active);
    setPatientMenuOptions(menuOptions);
    setColumns(patientColumnMap[idx]);
  };

  const handleHypertensionAssessmentClick = (patient: string) => {
    trackTappedAssessment('assessment/hypertension/start_new_assessment');
    navigate(ROUTE.ASSESSMENT_HYPERTENSION, {
      state: { wardPatientId, patient },
    });
  };

  const activeMenuOption = (idx: number) => {
    const menuOption = patientMenuOptions[idx];
    return menuOption && menuOption.active;
  };

  if (loading && isInit) {
    return <SpinnerLoader id="loader" data-testid="loader" />;
  }

  if (error || !data?.wardPatient) {
    return (
      <ErrorNotification
        id="patient-view"
        action="retrieving the patient details"
        onTryAgainClick={() => refetch()}
      />
    );
  }

  const wardPatientUI = buildWardPatientUI(data.wardPatient as WardPatient);

  const patientDetailsHeaderUI = buildPatientHeaderUI(wardPatientUI);
  const hasBloodPressureMonitoring =
    wardPatientUI.supportedObservationTypes.includes(
      ObservationType.BloodPressure
    );

  window.onpopstate = () => {
    navigate(ROUTE.HOME);
  };

  return (
    <>
      {loading && !isInit && <SpinnerLoader id="loader" data-testid="loader" />}
      <Grid>
        <Row>
          <Col from={1}>
            <PatientHeader
              {...patientDetailsHeaderUI}
              handleHypertensionAssessmentClick={() =>
                handleHypertensionAssessmentClick(patientDetailsHeaderUI.name)
              }
            />
          </Col>
        </Row>
        {isTaskListFeatureFlagEnabled && (
          <TaskBar
            wardPatientId={wardPatientId}
            personId={wardPatientUI.personId!}
            setShowTaskListPanel={setShowTaskListPanel}
          />
        )}
        <Row>
          <Col from={1}>
            <MenuBarWrapper>
              <MenuBar
                variant="secondary"
                options={patientMenuOptions}
                id="patient-options"
                onClick={handleMenuClick}
              />
            </MenuBarWrapper>
          </Col>
        </Row>
        <Row>
          <Col from={1}>
            <PatientWrapper columns={columns}>
              {activeMenuOption(0) && (
                <PatientHealthVitals
                  hasBloodPressureMonitoring={hasBloodPressureMonitoring}
                  wardPatientUI={wardPatientUI}
                />
              )}
              {activeMenuOption(1) && (
                <PatientOverview
                  wardPatientId={wardPatientId}
                  wardPatient={data.wardPatient as WardPatient}
                />
              )}
              {activeMenuOption(2) && (
                <PatientHistoricAssessments
                  wardPatientId={wardPatientId}
                  startAssessment={() =>
                    handleHypertensionAssessmentClick(
                      patientDetailsHeaderUI.name
                    )
                  }
                  canStartAssessment={checkIfAssessmentStart(
                    wardPatientUI.monitoredConditions
                  )}
                />
              )}
              {activeMenuOption(3) && (
                <PatientHealthInformation
                  wardPatientId={wardPatientId}
                  personId={wardPatientUI.personId!}
                />
              )}
              {activeMenuOption(4) && (
                <PatientLifestyle
                  wardPatientId={wardPatientId}
                  personId={wardPatientUI.personId!}
                />
              )}
              <SideMenu
                admissionStatus={wardPatientUI.admissionStatus}
                admissionStatusLastUpdated={
                  wardPatientUI.admissionStatusLastUpdated
                }
                setShowNotesPanel={setShowNotesPanel}
                setShowTaskListPanel={setShowTaskListPanel}
                monitoredConditions={wardPatientUI.monitoredConditions}
              />
            </PatientWrapper>
            {shouldShowTaskList && (
              <TaskList
                personId={wardPatientUI.personId!}
                wardPatientId={wardPatientId}
                showTaskListPanel={showTaskListPanel}
                setShowTaskListPanel={setShowTaskListPanel}
              />
            )}
            {showNotesPanel && (
              <Notes
                wardPatientId={wardPatientId}
                showNotesPanel={showNotesPanel}
                setShowNotesPanel={setShowNotesPanel}
              />
            )}
            <DeleteTask />
            <UncompleteTask />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
