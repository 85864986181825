import type { Dispatch } from 'react';
import { createContext } from 'react';

import { useAppContext } from '../useAppContext';
import type { TasksActions, TasksState } from 'reducers/tasks/types';

export const TasksContext = createContext<TasksState | null>(null);
export const TasksDispatchContext =
  createContext<Dispatch<TasksActions> | null>(null);

export const useTasks = () => useAppContext(TasksContext);
export const useTasksDispatch = () => useAppContext(TasksDispatchContext);
