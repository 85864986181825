import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';

export const DeleteTask = () => {
  const { showDeleteTaskModal } = useTasks();
  const tasksDispatch = useTasksDispatch();

  const handleClose = () => {
    tasksDispatch({
      type: 'resetShowDeleteTaskModal',
    });
    tasksDispatch({
      type: 'resetTaskDetails',
    });
  };

  const { onSubmit, loading } = useAddOrUpdateTaskList(handleClose);

  return (
    <Modal
      onClose={handleClose}
      isModalOpen={showDeleteTaskModal}
      id="delete-task-modal"
    >
      <ModalBody>
        <TextWrapper>
          <ModalTitle title="This task will be deleted" />
          <ModalStrapline>
            Once deleted, it will no longer be accessible to you. If you need to
            access deleted tasks please contact our team.
          </ModalStrapline>
        </TextWrapper>
        <ButtonWrapper>
          <Button id="delete-task-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id="delete-task-proceed"
            onClick={() => onSubmit({})}
            variant="primaryRed"
            isLoading={loading}
          >
            Delete
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
