import { BTHealthIcon } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import type { DraftTaskNotificationProps } from '../types';
import {
  CloseButton,
  InlineWrapper,
  TaskButton,
  TaskNotification,
} from '../styles';
import { getConsultationTimeframe } from '../utils';
import { BoldText } from 'components/BoldText';
import { capitalize } from 'utils/string.utils';
import { ConsultationTimeframeMap } from 'mappings/enums';
import { TaskListStatus, TaskListType } from 'services/graphql';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';

const getLabel = (type: TaskListType) => {
  switch (type) {
    case TaskListType.SendTestInvite:
      return 'Test';
    case TaskListType.SendConsultationInvite:
    default:
      return 'Consultation';
  }
};

export const DraftTaskNotification = ({
  task,
  setShowTaskListPanel,
}: DraftTaskNotificationProps) => {
  const tasksDispatch = useTasksDispatch();
  const { taskDetails } = useTasks();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit, loading, error } = useAddOrUpdateTaskList(() =>
    tasksDispatch({
      type: 'resetTaskDetails',
    })
  );

  const onCloseTask = () => {
    tasksDispatch({
      type: 'setTaskDetails',
      taskId: task.id,
    });
    setIsSubmitting(true);
  };

  const onEditTask = () => {
    tasksDispatch({
      type: 'setTaskDetails',
      taskId: task.id,
    });
    tasksDispatch({
      type: 'setIsEditTask',
      isEditTask: true,
    });
    setShowTaskListPanel(true);
  };

  useEffect(() => {
    if (isSubmitting && taskDetails?.id) {
      onSubmit({
        status: TaskListStatus.Dismissed,
      });
    }
  }, [isSubmitting, taskDetails]);

  useEffect(() => {
    if (isSubmitting && error) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, error]);

  return (
    <TaskNotification
      icon="Task"
      type="informative"
      data-testid="draft-task-notification"
    >
      <>
        <InlineWrapper>
          <span>
            <BoldText>{`${capitalize(getLabel(task.type))} status`}</BoldText>{' '}
            was set to <BoldText>Required</BoldText>
            {task.type === TaskListType.SendConsultationInvite &&
              task.dueType !== 'overdue' && (
                <>
                  {' '}
                  in{' '}
                  <BoldText>
                    {
                      ConsultationTimeframeMap[
                        getConsultationTimeframe(task.dueDateTime)!
                      ]
                    }
                  </BoldText>
                </>
              )}{' '}
          </span>
          <TaskButton
            id="set-task-button"
            onClick={onEditTask}
            isLoading={loading || isSubmitting}
          >
            Set task
          </TaskButton>
        </InlineWrapper>
        {!loading && !isSubmitting && (
          <CloseButton data-testid="close-button" onClick={onCloseTask}>
            <BTHealthIcon icon="Close" size={8} />
          </CloseButton>
        )}
      </>
    </TaskNotification>
  );
};
