import {
  BTHealthIcon,
  StatusChip,
  Wrapper,
  colors,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';

import type { FilterStatusChipGroupProps } from './types';
import {
  FilterStatusChipGroupWrapper,
  FilterStatusChipWrapper,
} from './styles';
import {
  ConsultationStatusMap,
  TaskListTypeMap,
  TestStatusMap,
} from 'mappings/enums';
import {
  type TaskListType,
  type MonitoredConditionConsultationStatusType,
  type MonitoredConditionTestStatusType,
} from 'services/graphql';
import type { SubFilter } from 'filters/types';

const getStatusChipText = ({ value, type }: SubFilter) => {
  switch (type) {
    case 'ConsultationStatus':
      return `Consultation ${ConsultationStatusMap[
        value as MonitoredConditionConsultationStatusType
      ].toLowerCase()}`;
    case 'TestStatus':
      return `Test ${TestStatusMap[
        value as MonitoredConditionTestStatusType
      ].toLowerCase()}`;
    case 'TaskDueType':
      return TaskListTypeMap[value as TaskListType];
    default:
      return value;
  }
};

export const FilterStatusChipGroup = ({
  statusFilters,
  onCloseFilter,
}: FilterStatusChipGroupProps) => (
  <FilterStatusChipGroupWrapper data-testid="filter-status-chip-group">
    {statusFilters.map((chip) => {
      const id = `${toHyphenCase(chip.value)}-${toHyphenCase(chip.type)}`;
      return (
        <StatusChip
          id={`filter-item-${id}`}
          data-testid={`filter-item-${id}`}
          chipColor="grey.grey03"
          textColor="grey.grey09"
          key={id}
        >
          <FilterStatusChipWrapper>
            <Wrapper>{getStatusChipText(chip)}</Wrapper>
            <Wrapper onClick={() => onCloseFilter(chip)} role="button">
              <BTHealthIcon
                icon="Close"
                title="Remove filter"
                size={12}
                color={colors.grey.grey09}
              />
            </Wrapper>
          </FilterStatusChipWrapper>
        </StatusChip>
      );
    })}
  </FilterStatusChipGroupWrapper>
);
