import {
  SidePanel,
  SpinnerLoader,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { TaskListForm } from '../TaskListForm';
import { TasksList } from '../TasksList';
import type { TaskListPanelProps } from '../types';

const TaskListPanel = ({
  open,
  setOpen,
  onRequestClose,
  loading,
  refetch,
}: TaskListPanelProps) => (
  <SidePanel
    id="task-list-panel"
    open={open}
    setOpen={setOpen}
    handleClose={onRequestClose}
    title={
      <Text color={colors.grey.grey10} fontSize={fontSizes.h4} fontWeight={500}>
        Task list
      </Text>
    }
    maxWidth="400px"
  >
    <TaskListForm refetch={refetch} />
    {loading && (
      <SpinnerLoader data-testid="tasks-list-loader" id="tasks-list-loader" />
    )}
    {!loading && <TasksList />}
  </SidePanel>
);

export default TaskListPanel;
