import { ConsultationStatusMap, TestStatusMap } from 'mappings/enums';
import {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
  TaskListType,
} from 'services/graphql';

export type UncompleteTaskTypes =
  | TaskListType.SendConsultationInvite
  | TaskListType.ConfirmConsultationAttended
  | TaskListType.ConfirmConsultationBooked
  | TaskListType.SendTestInvite
  | TaskListType.ConfirmTestResultsReturned
  | TaskListType.ReviewTestResults
  | TaskListType.ConfirmTestBooked
  | TaskListType.ConfirmTestAttended;

export type UncompleteConsultationStatusTypes =
  | TaskListType.SendConsultationInvite
  | TaskListType.ConfirmConsultationAttended
  | TaskListType.ConfirmConsultationBooked;

export type UncompleteTestStatusTypes =
  | TaskListType.SendTestInvite
  | TaskListType.ConfirmTestResultsReturned
  | TaskListType.ReviewTestResults
  | TaskListType.ConfirmTestBooked
  | TaskListType.ConfirmTestAttended;

export const UncompleteTaskDescMapper: {
  [key in UncompleteTaskTypes]: string[];
} = {
  [TaskListType.SendConsultationInvite]: [
    ConsultationStatusMap.INVITED,
    ConsultationStatusMap.REQUIRED,
  ],
  [TaskListType.ConfirmConsultationAttended]: [
    ConsultationStatusMap.COMPLETED,
    ConsultationStatusMap.BOOKED,
  ],
  [TaskListType.ConfirmConsultationBooked]: [
    ConsultationStatusMap.BOOKED,
    ConsultationStatusMap.INVITED,
  ],
  [TaskListType.SendTestInvite]: [
    TestStatusMap.INVITED,
    TestStatusMap.REQUIRED,
  ],
  [TaskListType.ConfirmTestResultsReturned]: [
    TestStatusMap.RETURNED,
    TestStatusMap.AWAITING_RESULTS,
  ],
  [TaskListType.ReviewTestResults]: [
    TestStatusMap.REVIEWED,
    TestStatusMap.RETURNED,
  ],
  [TaskListType.ConfirmTestBooked]: [
    TestStatusMap.BOOKED,
    TestStatusMap.INVITED,
  ],
  [TaskListType.ConfirmTestAttended]: [
    TestStatusMap.AWAITING_RESULTS,
    TestStatusMap.BOOKED,
  ],
};

export const UncompleteConsultationStatusMapper: Record<
  UncompleteConsultationStatusTypes,
  MonitoredConditionConsultationStatusType
> = {
  [TaskListType.SendConsultationInvite]:
    MonitoredConditionConsultationStatusType.Invited,
  [TaskListType.ConfirmConsultationAttended]:
    MonitoredConditionConsultationStatusType.Completed,
  [TaskListType.ConfirmConsultationBooked]:
    MonitoredConditionConsultationStatusType.Booked,
};

export const UncompleteTestStatusMapper: Record<
  UncompleteTestStatusTypes,
  MonitoredConditionTestStatusType
> = {
  [TaskListType.SendTestInvite]: MonitoredConditionTestStatusType.Invited,
  [TaskListType.ConfirmTestResultsReturned]:
    MonitoredConditionTestStatusType.Returned,
  [TaskListType.ReviewTestResults]: MonitoredConditionTestStatusType.Reviewed,
  [TaskListType.ConfirmTestBooked]: MonitoredConditionTestStatusType.Booked,
  [TaskListType.ConfirmTestAttended]:
    MonitoredConditionTestStatusType.AwaitingResults,
};

export const shouldShowUncompletePopup = (
  type: TaskListType,
  testStatus: MonitoredConditionTestStatusType | null,
  consultationStatus: MonitoredConditionConsultationStatusType | null
) => {
  const isNotValidConsultationStatusMapping =
    UncompleteConsultationStatusMapper[
      type as UncompleteConsultationStatusTypes
    ] !== undefined &&
    UncompleteConsultationStatusMapper[
      type as UncompleteConsultationStatusTypes
    ] !== consultationStatus;
  const isNotValidTestStatusMapping =
    UncompleteTestStatusMapper[type as UncompleteTestStatusTypes] !==
      undefined &&
    UncompleteTestStatusMapper[type as UncompleteTestStatusTypes] !==
      testStatus;
  const isTypeOther = type === TaskListType.Other;

  if (
    isTypeOther ||
    isNotValidConsultationStatusMapping ||
    isNotValidTestStatusMapping
  ) {
    return false;
  }

  return true;
};
