import { colors, fontSizes, Text, Wrapper } from '@bt-healthcare/ui-toolkit';

import type { PatientAssessmentHeaderProps } from './types';
import { PatientDetailWrapper, PatientAssessmentHeaderWrapper } from './styles';
import { PatientDetail } from 'components/PatientDetail';

export const PatientAssessmentHeader = ({
  name,
  vitalsForMonitoring,
  monitoredConditions,
  nhsNumber,
  birthDate,
  phoneNumber,
}: PatientAssessmentHeaderProps) => (
  <PatientAssessmentHeaderWrapper id="patient-details">
    <Wrapper>
      <Text
        color={colors.primaryIndigo.indigo08}
        fontWeight={500}
        fontSize={fontSizes.h4}
        data-testid="patient-name"
      >
        {name}
      </Text>
      <PatientDetailWrapper>
        <PatientDetail id="date-of-birth" label="D.O.B">
          {birthDate}
        </PatientDetail>
        <PatientDetail id="patient-nhsid" label="NHS No">
          {nhsNumber}
        </PatientDetail>
        <PatientDetail id="patient-phone" label="Phone">
          {phoneNumber}
        </PatientDetail>
        <PatientDetail
          id="monitored-condition-text"
          label="Monitored condition(s)"
        >
          {monitoredConditions}
        </PatientDetail>
        <PatientDetail
          id="vitals-for-monitoring-text"
          label="Vitals for monitoring"
        >
          {vitalsForMonitoring}
        </PatientDetail>
      </PatientDetailWrapper>
    </Wrapper>
  </PatientAssessmentHeaderWrapper>
);
