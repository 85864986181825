import {
  BTHealthIcon,
  colors,
  useClickOutside,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { type MutableRefObject, useRef, useState } from 'react';

import { FilterDropDownMenu } from '../FilterDropdownMenu';
import type { TasksFilterProps } from '../types';
import {
  FilterButton,
  FilterDropdownWrapper,
  DropdownWrapper,
  Spacer,
} from './styles';

export const TasksFilter = ({
  filterStatuses,
  setFilterStatuses,
}: TasksFilterProps) => {
  const [selected, setSelected] = useState(false);
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [statusFilters, setStatusFilters] = useState<string[]>(filterStatuses);
  const isActive = statusFilters.length > 0;

  const openDropdownMenu = () => {
    setSelected(true);
    setDropdownMenuOpen(true);
  };

  const closeDropdownMenu = () => {
    setSelected(false);
    setDropdownMenuOpen(false);
  };

  const handleFilterStatusSelect = (statuses: any[]) => {
    setFilterStatuses(statuses);
    closeDropdownMenu();
  };

  const handleClear = () => {
    setStatusFilters([]);
  };

  const handleDesktopButtonClick = () => {
    if (dropdownMenuOpen) {
      return closeDropdownMenu();
    }

    return openDropdownMenu();
  };

  const handleTargetChecked = (target: any, status: any) => {
    let updatedFilter: string[];
    if (target.checked) {
      updatedFilter = [...(statusFilters ?? []), status];
    } else {
      updatedFilter =
        statusFilters.filter((item: any) => item !== status) ?? [];
    }
    setStatusFilters(updatedFilter);
  };

  const filterRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(filterRef, () => {
    closeDropdownMenu();
  });

  return (
    <Wrapper ref={filterRef}>
      <FilterDropdownWrapper>
        <FilterButton
          id="task-filter-button"
          variant="tertiary"
          $selected={selected}
          $active={isActive}
          onClick={handleDesktopButtonClick}
        >
          <BTHealthIcon
            icon="Filter"
            color={
              selected || isActive
                ? colors.primaryIndigo.indigo08
                : colors.grey.grey08
            }
            size={20}
          />
        </FilterButton>

        {dropdownMenuOpen && (
          <DropdownWrapper>
            <Spacer />
            <FilterDropDownMenu
              statusFilter={statusFilters}
              handleFilterStatusSelect={handleFilterStatusSelect}
              clearFilter={handleClear}
              handleTargetChecked={handleTargetChecked}
            />
          </DropdownWrapper>
        )}
      </FilterDropdownWrapper>
    </Wrapper>
  );
};
